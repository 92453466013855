<template>
  <div>
    <CRow>
      <CCol sm="12">
        <MySubscriptionForm ref="subscriptionForm" @refreshBills="refreshBills"></MySubscriptionForm>
        <MyHistorialTable ref="historialTable"
          :items="bills"
          hover
          striped
          border
          small
          fixed
          caption="Facturas"
          ></MyHistorialTable>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import MySubscriptionForm from '../../components/configuration/MySubscriptionForm.vue';
import MyHistorialTable from '../../components/configuration/MyHistorialTable.vue';
import account_ws from '../../services/account';
import store from '../../store';

export default {
  name: 'MySubscription',
  components: { MySubscriptionForm, MyHistorialTable },
  data: function () {
		return {
            customers: [],
            bills: [],
        }
  },
  mounted: async function() {
    this.refreshBills();
  },
  methods: {
    async refreshBills(){
      this.loading();
      let response = await account_ws.getBills(); 

      if(response.type == "success"){
        this.bills = response.data;
      }
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
