<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="openNewCardModal"
    >
      <CForm id="card_form">
        <CInput
          type="hidden"
          name="conektaTokenId"
          :value.sync="$v.form.conektaTokenId.$model"
          id="input_token"
        />
        <CRow>   
          <CCol md="12">            
            <CInput
              label="Titular de la tarjeta:"
              :lazy="false"              
              :isValid="checkIfValid('name')"
              :value.sync="$v.form.name.$model"
              data-conekta="card[name]"
              placeholder="Juan Pérez"
              invalidFeedback="Este campo es requerido."
            />
          </CCol>
        </CRow>  
        <CRow>  
          <CCol md="12">
            <CInput
              label="Número de tarjeta:"
              :lazy="false"
              :isValid="checkIfValid('card_number')"
              :value.sync="$v.form.card_number.$model"
              data-conekta="card[number]"
              placeholder="4242424242424242"
              invalidFeedback="Este campo es requerido y debe contener 15 o 16 dígitos numéricos."
            />
          </CCol>
        </CRow>  
        <CRow>
          <CCol md="6">            
            <label for="">Fecha de Expiración: </label>
            <CRow>
              <CCol md="6">
                <CInput
                  :lazy="false"
                  :isValid="checkIfValid('exp_month')"
                  :value.sync="$v.form.exp_month.$model"
                  data-conekta="card[exp_month]"
                  placeholder="MM"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol md="6">
                <CInput
                  :lazy="false"
                  :isValid="checkIfValid('exp_year')"
                  :value.sync="$v.form.exp_year.$model"
                  data-conekta="card[exp_year]"
                  placeholder="YY"
                  invalidFeedback="Este campo es requerido."
                />  
              </CCol>  
            </CRow>
          </CCol>              
          <CCol md="6">
            <CInput
              label="CVC:"
              :lazy="false"
              :isValid="checkIfValid('cvc')"
              :value.sync="$v.form.cvc.$model"
              data-conekta="card[cvc]"
              placeholder="000"
            />
          </CCol>
          <CCol md="12">
            <hr>
            <div>
              <label for=""><b> Información de Facturación de la Tarjeta </b></label>              
            </div>            
          </CCol>
          <br>
          <br><br>          
          <CCol md="12">
            <CInput
              label="Dirección:"
              :lazy="false"
              :isValid="checkIfValid('street')"
              :value.sync="$v.form.street.$model"
              data-conekta="card[address][street1]"
              placeholder=""
              invalidFeedback="Este campo es requerido."
            />
          </CCol>
          <CCol md="6">
            <CInput
              label="Colonia:"
              :lazy="false"
              :isValid="checkIfValid('colony')"
              :value.sync="$v.form.colony.$model"
              data-conekta="card[address][street2]"
              placeholder=""
              invalidFeedback="Este campo es requerido."
            />
            <CInput
              type="hidden"
              data-conekta="card[address][street3]"
              value=""
            />            
          </CCol>          
          <CCol md="6">
            <CInput
              label="Ciudad:"
              :lazy="false"
              :isValid="checkIfValid('city')"
              :value.sync="$v.form.city.$model"
              data-conekta="card[address][city]"
              placeholder=""
              invalidFeedback="Este campo es requerido."
            />
          </CCol>
          <CCol md="6">
            <CSelect
              label="Estado: "
              :value.sync="$v.form.state.$model"
              data-conekta="card[address][state]"
              :options="statesOptions"
            />
          </CCol>
          <CCol md="6">
            <CInput
              label="Código Postal:"
              :lazy="false"
              :isValid="checkIfValid('zipcode')"
              :value.sync="$v.form.zipcode.$model"
              data-conekta="card[address][zip]"
              placeholder=""
              invalidFeedback="Este campo es requerido."
            />
          </CCol>
          <CInput
              type="hidden"
              :value.sync="$v.form.country.$model"
              data-conekta="card[address][country]"
              :isValid="checkIfValid('country')"
              :options="countriesOptions"
            />
        </CRow>
      </CForm>
        <template #footer>
          <CButton @click="closeModal()" color="secondary">Cancelar</CButton>
          <CButton @click="store()" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>
<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  integer,
  email,
} from "vuelidate/lib/validators";
import general_ws from "../../../services/general";
import account_ws from "../../../services/account";
import store from "../../../store";

export default {
  name: "NewCardModal",
  props: {
    type: "",
  },
  components: {},
  data() {
    return {
      openNewCardModal: false,
      title: "Información de la Tarjeta",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      conektaTokenId: "",
      states: [],
      statesOptions: [],
      countries: [],
      countriesOptions: [],
    };
  },
  mounted: async function () {
    let response2 = await general_ws.getStates();

    if (response2.type == "success") {
      this.states = response2.data;
    }

    let response3 = await general_ws.getCountries();

    if (response3.type == "success") {
      this.countries = response3.data;
    }

    this.statesOptions = await this.$parseSelectOptionsOrdered(
      this.states,
      "name",
      "name"
    );
    this.countriesOptions = await this.$parseSelectOptionsOrdered(
      this.countries,
      "name",
      "name"
    );
  },
  methods: {
    getEmptyForm() {
      return {
        id: 0,
        name: "",
        card_number: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
        street: "",
        colony: "",
        city: "",
        zipcode: "",
        conektaTokenId: "",
        state: "Jalisco",
        country: "México",
      };
    },
    openModal() {
      this.openNewCardModal = true;
      this.form = this.getEmptyForm();
    },
    async store() {
      this.$v.$touch();      
      if(this.isValid){
        this.loading();
        let card_form = document.getElementById("card_form");

        var params = this.form;
        var response = "";
        this.submitted = true;
        var element = this;

        await Conekta.Token.create(
          card_form,
          async function (token) {
            params.conekta_token_id = token.id;
            response = await account_ws.storeCard(params);
            if (response.type == "success") {
              element.$emit("refresh");
            }

            element.loaded();
            element.showToast(response.type, response.message);
            
            element.closeModal();
          },
          function (error) {
            element.loaded();
            element.showToast("error", "Ha habido un error al ingresar la tarjeta. Por favor comunicarse con un Administrador.");            
            console.log(error.message_to_purchaser);            
            element.closeModal();
          }
        );
      }     
    },
    closeModal() {
      this.openNewCardModal = false;
      this.conektaTokenId = "";
      this.submitted = false;
      this.form = this.getEmptyForm();
      this.$v.$reset();
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }

      return !field.$invalid;
    },
    loading() {
      store.commit("loading");
    },
    loaded() {
      store.commit("loaded");
    },
    showToast(type, message) {
      store.commit("toast", { color: type, message: message });
    },
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
      conektaTokenId: {
      },
      card_number: {
        required,
      },
      exp_month: {
        required,
        integer,
        minLength: minLength(2),
        maxLength: maxLength(2),
      },
      exp_year: {
        required,
        integer,
        minLength: minLength(2),
        maxLength: maxLength(2),
      },
      cvc: {
        required,
        integer,
        minLength: minLength(3),
        maxLength: maxLength(3),
      },
      street: {
        required,
      },
      colony: {
        required,
      },
      city: {
        required,
      },
      zipcode: {
        required,
        integer,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
      state: {
        required,
      },
      country: {
        required,
      },
    },
  },
};
</script>