<template>
    <CModal :title="title" color="warning" :show.sync="warningModal">   
        <p>{{text}} <br><br><b>¿Desea proceder con la operación?</b></p>
        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="warningModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="warningModal = false" color="secondary">Cerrar</CButton>
          <CButton @click="buy" color="warning">Comprar</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
  name: 'WarningBuySubscriptionModal',
  props: {

  },
  data () {
    return {
      warningModal: false,
      title: "Advertencia",
      name: 'Advertencia',
      text: "",
      package_stamp: {},
    }
  },
  mounted: async function() {
  },
  methods: {
    openModal (text = "",package_stamp) {
      this.warningModal = true;
      this.text = text;
      this.submitted = false;
      this.package_stamp = package_stamp;
      
    },
    async buy () {
      this.$emit("buy",this.package_stamp);
      this.closeModal();
    },
    closeModal () {
      this.warningModal = false;

      this.submitted = false;
    },      
  },
}
</script>