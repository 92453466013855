import axios from 'axios'

export default {
    getCfdiUsos: () => axios.get('cfdi-usos'),
    getCfdiUsosByRegimen: (regimen) => regimen ? axios.get('cfdi-usos/'+regimen) : axios.get('cfdi-usos'),
    getRegimens: () => axios.get('regimens'),
    getPhysicalRegimens: () => axios.get('regimens/physical'),
    getMoralRegimens: () => axios.get('regimens/moral'),
    getStates: () => axios.get('states'),
    getCountries: () => axios.get('countries'),
    getPaymentForms: () => axios.get('payment-forms'),
    getPaymentMethods: () => axios.get('payment-methods'),
    getRelationTypes: () => axios.get('relation-types'),    
    getCancelationReasons: () => axios.get('cancelation-reasons'),
    getPackageStamps: () => axios.get('package-stamps'),
    getAddendas: () => axios.get('addedas'),
    hasAddenda: (code) => axios.get('has-addenda/'+code),
}