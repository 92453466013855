<template>
  <CRow>
    <CCol md="6">
        <CCard >
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-credit-card"/> Información de Pago
              <div class="card-header-actions"></div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CRow v-if="showCard">
              <CCol md="7">
                <label for=""><b>No. De Tarjeta</b></label>
                <div>
                  <p>
                    <img :src="card.logo" alt="" height="20px">
                    {{(card.last4) ? " **** " + card.last4 : ""}}
                  </p>
                </div>                
                <label for=""><b>Titular</b></label>
                <p>{{card.name}}</p>
                <label for=""><b>Fecha de expiración</b></label>
                <p>{{(card.exp_month) ? card.exp_month + "/" +card.exp_year: ""}}</p>
              </CCol>
              <CCol md="5">
                <label for=""><b>Dirección</b></label>
                <p>
                  {{card.street}}{{(card.colony) ? ", "+ card.colony : ""}}{{(card.city) ? ", "+ card.city: ""}} {{(card.state) ? ", "+ card.state: ""}}{{(card.country) ? ", "+ card.country: ""}} {{(card.zipcode) ? "C.P. " + card.zipcode :"" }}
                </p>
              </CCol>
            </CRow>
            <CRow v-if="!showCard">
              <CCol md="12" class="text-center" style="height: 207px;">
                <br><br><br><br>
                <label for=""><b>No has registrado tu información de pago.</b></label>
                <br>
                <br><br><br><br>               
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow>
              <CCol v-if="showCard" md="6" class="text-center">
                <CButton @click="openDeleteCardModal()" color="danger" variant="outline">Eliminar Tarjeta</CButton>
              </CCol>
               <CCol :md="(!showCard) ? '12': '6'" class="text-center">
                <CButton @click="openNewCardModal()" color="info">Nueva Tarjeta</CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    <CCol md="6">
        <CCard >
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-briefcase"/> Suscripción
              <div class="card-header-actions"></div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="7">
                <label for=""><b>Paquete</b></label>
                <p>{{subscription.package.name}}</p>
                <label for=""><b>Timbres del Paquete</b></label>
                <p>{{(subscription.package.quantity > 0) ? subscription.package.quantity : "Ilimitados"}}</p>
                <label for=""><b>Periodicidad</b></label>
                <p>{{(subscription.package.code == "FREE") ? "Oportunidad unica" : subscription.package.vigency}}</p>
              </CCol>
              <CCol md="5">
                <label for=""><b>Timbres Restantes</b></label>
                <p>{{subscription.stamps}}</p>
                <label for=""><b>Vigencia</b></label>
                <p>{{subscription.deadline | date}}</p>                
                <label for=""><b>Próximo Pago</b></label>
                <p>{{(subscription.package.code == "FREE" || subscription.active == 0) ? "Sin cobro pendiente" : subscription.deadline | date}}</p>
              </CCol>
            </CRow>
          </CCardBody>
         <CCardFooter>
            <CRow>
              <CCol v-if="(subscription.package.code != 'FREE' && subscription.active == 1)" md="6" class="text-center">
                <CButton @click="openCancelSubscriptionModal()" color="danger" variant="outline">Cancelar Suscripción</CButton>
              </CCol>
               <CCol :md="(subscription.package.code != 'FREE' && subscription.active == 1) ? '6': '12'" class="text-center">
                <CButton @click="openBuySubscriptionModal()" color="info">Comprar Timbres</CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
    </CCol>   
    <NewCardModal ref="newCardModal" @refresh="refreshCard"></NewCardModal>
    <CancelSubscriptionModal ref="cancelSubscriptionModal" @refresh="refreshSubscription"></CancelSubscriptionModal>
    <DeleteCardModal ref="deleteCardModal" @refresh="refreshCard"></DeleteCardModal>
    <BuySubscriptionModal ref="buySubscriptionModal" @refresh="refreshSubscription"></BuySubscriptionModal>
  </CRow>  
</template>

<script>
import store from '../../store';
import NewCardModal from './modals/NewCardModal.vue';
import CancelSubscriptionModal from './modals/CancelSubscriptionModal.vue';
import DeleteCardModal from './modals/DeleteCardModal.vue';
import BuySubscriptionModal from './modals/BuySubscriptionModal.vue'
import account_ws from '../../services/account';

export default {
  name: 'MySubscriptionForm',
  components: { NewCardModal, CancelSubscriptionModal, BuySubscriptionModal, DeleteCardModal },
  props: {

  },
  data() {
    return {
      subscription: {
        package: {
        }
      },
      card: {},
      showCard: false,
      submitted: false
    }
  },
  mounted: async function() {    
    this.refreshSubscription();
    this.refreshCard();
  },
  methods: {
    async openNewCardModal () {
      this.$refs.newCardModal.openModal();
    },
    async openCancelSubscriptionModal () {
      this.$refs.cancelSubscriptionModal.openModal(this.subscription.deadline);
    },    
    async openDeleteCardModal () {
      this.$refs.deleteCardModal.openModal(this.subscription.deadline);
    },      
    async openBuySubscriptionModal () {
      this.$refs.buySubscriptionModal.openModal(this.subscription);
    },
    async refreshCard () {
      let response = await account_ws.getCard(); 
      if(response.type == "success" ){
        if(response.data !== null){
          this.card = response.data;
          this.showCard = true;
        }else{          
          this.card = {};
          this.showCard = false;
          this.refreshSubscription();
        }        
      }
    },     
    async refreshSubscription () {
      let response = await account_ws.getSubscription(); 

      if(response.type == "success"){
        this.subscription = response.data;
        this.$emit("refreshBills", true);
      }
    },          
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
}
</script>
