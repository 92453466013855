<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="openBuySubscriptionModal"
    >
      <CForm>
        <CRow>
          <CCol :key="key" md="4" v-for="(subscription,key) in packageStamps">
          <CCard >
            <CCardHeader color="dark" class="text-center">
              <slot name="header">
                <div style="color: white;">
                  <h3>{{subscription.name}}</h3>
                </div>
                
                <div class="card-header-actions"></div>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol md="12" class="text-center">
                  <CRow>
                  <CCol md="10" class="text-center"  style="padding-left:0px; margin-left:20px;">
                    <h2>${{subscription.price}}</h2>
                  </CCol>
                  <CCol md="2" class="text-center" style="padding:0px; margin-left: -40px; margin-top:-10px;">
                    <label style="font-size: 10px;">+ IVA</label>
                  </CCol>
                  </CRow>
                </CCol>  
                <CCol md="12" class="text-center">                  
                  <label for=""><CIcon name="cil-file" style="color: #3399FF;"/> <u>{{(subscription.quantity > 0) ? subscription.quantity + " Timbres" : "Timbres ILIMITADOS"}} </u></label>
                  <label for="">Vigencia <b>{{subscription.vigency }}</b></label>
                </CCol>  
                <CCol md="12" :key="code" v-for="(content,code) in subscription.content" class="text-center">
                  <hr> 
                    <CIcon name="cil-check-circle" style="color: #2EB85C;"/>
                   {{content}}                  
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
                <CRow>
                  <CCol md="12" class="text-center">
                    <CButton @click="buySubscription(subscription)" color="info">Comprar</CButton>
                  </CCol>
                </CRow>
            </CCardFooter>
          </CCard>
          </CCol>
        </CRow>        
      </CForm>      
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
        </template>
        <WarningBuySubscriptionModal ref="warningModalBuySubscription" @buy="buy"></WarningBuySubscriptionModal>
    </CModal>    
</template>

<script>
import store from '../../../store';
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import general_ws from '../../../services/general';
import account_ws from '../../../services/account';
import WarningBuySubscriptionModal from './WarningBuySubscriptionModal.vue'

export default { 
  name: 'BuySubscriptionModal',
  props: {
    type: ''
  },  
  components: {
    WarningBuySubscriptionModal,
  },
  data () {
    return {
      openBuySubscriptionModal: false,
      title: "Comprar Timbres", 
      color: "info",
      subscription: {},
      submitted: false,
      packageStamps: [],
    }
  },
  mounted: async function() {
    let response = await general_ws.getPackageStamps(); 

    if(response.type == "success"){
      this.packageStamps = this.$removeElementByCode(response.data,"FREE");
    }
  },
  methods: {
    openModal (subscription) {
      this.openBuySubscriptionModal = true;
      this.subscription = subscription.package;
      this.submitted = false;

    },
    async buySubscription (package_stamp) {
      let msj = ";"
      if(this.subscription.code != "FREE"){
        if(this.subscription.code == "UNLIMITED" && package_stamp.code != "UNLIMITED"){
          msj = "Al momento de realizar la compra perderá los beneficios del paquete ilimitado y se le asignará la cantidad de timbres correspondientes a su nueva compra."
          this.$refs.warningModalBuySubscription.openModal(msj,package_stamp);     
        }else if(Number(package_stamp.price) < Number(this.subscription.price)){
          msj = "Al momento de realizar la compra se le sumará la cantidad de timbres y se le aumentará la vigencia en un año a partir de la fecha de la compra.";
          this.$refs.warningModalBuySubscription.openModal(msj,package_stamp);     
        }else{
          this.buy(package_stamp);  
        }
      }else{
        this.buy(package_stamp);
      }    
    },
    async buy (subscription) {
      this.loading();
      this.submitted = true;
      
      let response = await account_ws.subscriptionBuy({package_code : subscription.code});
      this.$emit("refresh");      

      this.loaded();
      this.showToast(response.type, response.message);
      this.closeModal();
    },
    warningModalSubscription () {
      this.$refs.warningModalBuySubscription.openModal();
    },
    closeModal () {
      this.openBuySubscriptionModal = false;

      this.submitted = false
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },  
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },      
  },
}
</script>