<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-copy"/> Historial de Pagos
      </slot>
    </CCardHeader>
    <CCardBody>
      <br>
      <CRow>
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items"
            :fields="fields"
            :items-per-page="small ? 30 : 10"
            :dark="dark"
            pagination
          >
            <template #date="{item}"><td class="w150 text-center">{{ item.date | date }}</td></template>
            <template #card="{item}"><td class="w150 text-center"><img :src="item.logo" alt="" height="20px"> **** {{ item.last4 }}</td></template>
            <template #folio="{item}"><td class="w150 text-center">{{ item.serial+item.folio }}</td></template>
            <template #subtotal="{item}"><td class="w100 text-center"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.subtotal | currency }}</td></template>
            <template #transfered_taxes="{item}"><td class="w100 text-center"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.transfered_taxes | currency }}</td></template>
            <template #withheld_taxes="{item}"><td class="w100 text-center"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.withheld_taxes | currency }}</td></template>
            <template #total="{item}"><td class="w100 text-center"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.total | currency }}</td></template>
            <template #customer_name="{item}"><td class="text-lefy"><span style="font-weight: bold;">{{item.receptor}}</span><br>{{item.customer_name}}</td></template>
            <template #actions="{item}">
              <td class="table_actions b3">
                <CButton color="success" size="sm" @click="downloadXML(item.id)">XML</CButton>
                <CButton color="danger" size="sm" @click="downloadPDF(item.id)">PDF</CButton>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import store from '../../store'
import account_ws from '../../services/account';

export default {
  name: 'MyHistorialTable',
  components: { },
  props: {
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean, 
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      fields: [ 
        {key: 'date', label: 'Fecha'},
        {key: 'card', label: 'Tarjeta'},
        {key: 'folio', label: 'Folio'},
        {key: 'subtotal', label: 'Subtotal'},
        {key: 'transfered_taxes', label: 'Trasladados'},
        {key: 'withheld_taxes', label: 'Retenidos'},
        {key: 'total', label: 'Total'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      toasts: [],
    }
  },
  mounted: async function() {
  },
  methods: {
    async downloadXML(id){
      account_ws.downloadXML(id);
    },
    async downloadPDF(id){
      account_ws.downloadPDF(id);
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
