<template>
    <CModal :title="title" color="danger" :show.sync="deleteModal">   
        <p>Si decides Eliminar tu tarjeta se cancelara la suscripción ya no se realizarán cargos automáticos y tu cuenta estará vigente hasta <b>{{vigency | date}}</b></p>
        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="deleteModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="deleteModal = false" color="secondary">Cerrar</CButton>
          <CButton @click="destroy" color="danger">Eliminar Tarjeta</CButton>
        </template>
    </CModal>
</template>

<script>
import store from '../../../store';
import account_ws from '../../../services/account';

export default {
  name: 'DeleteCardModal',
  props: {

  },
  data () {
    return {
      deleteModal: false,
      title: "Eliminar Tarjeta",
      name: 'Eliminar Tarjeta',
      vigency: "",
    }
  },
  mounted: async function() {
  },
  methods: {
    openModal (vigency = "") {
      this.deleteModal = true;
      this.vigency = vigency;
      this.submitted = false;
      
    },
    async destroy () {
      this.loading();
      this.submitted = true;

      let response = await account_ws.deleteCard();
      if(response.type == "success"){
        this.$emit("refresh");
      }

      this.loaded();
      this.showToast(response.type, response.message);
        
      this.closeModal();
    },
    closeModal () {
      this.deleteModal = false;

      this.submitted = false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },        
  },
}
</script>